<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <v-row>
      <v-col cols="12" class="d-flex" style="flex-direction: column">
        <card-user :user="user" v-if="user" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    page: {
      title: 'Painel de Controle'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Usuário',
        disabled: false,
        to: '/user/profile'
      }
    ],
    user: ''
  }),
  methods: {
    ...mapMutations(['setLoading']),
    getUserData (idUser) {
      this.setLoading(true)
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      this.$axios.get(`/organizations/${id}/users/${idUser}`)
        .then(res => {
          this.user = res.data
        }).catch(() => {
          const msg = 'Erro na conexão, tente novamente'
          this.$store.dispatch('alert', { color: 'red', msg })
        }).finally(() => this.setLoading(false))
    },
    handleClick (url) {
      this.$router.push({ path: url })
    }
  },
  mounted () {
    const id = this.$route.params.id
    if (id) {
      this.getUserData(id)
    } else {
      this.$store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma organização' })
    }
  }
}
</script>
